import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';
export const Worringen: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Worringen,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Worringen"
          d="M220.2,49.4l0.4-0.1c-0.6-1.8-2.6-7.7-3.2-9.4c5.5-1.9,12.7-4.5,18.3-6.4c-0.7-2.1-2.3-6.7-3-8.7 c4.3-1.5,10.2-3.5,14.5-5c0.1,1,0.3,2.4,0.4,3.3c1.6-0.5,3.4-0.9,5-1.3c0,0,0.5,0.1,0.5,0.1l7.6,1.9c-0.1,0.7-0.1,1.4-0.1,2 c-1,23.7,16.1,44.5,39.4,49c4.5,0.9,8.9,1.6,12.9,2.8c22.7,5.1,64.7,27.3,64.7,27.3c-6.2,10.5-28.6,56.3-37.5,63.1 c-3.4,8-10.2,25.6-12,34c0,0-6,4-12,4s-18,4-18,4l-16-8l-8-8l-22-52l-18,8l-16,12c-0.1-0.1-11.9-34-12.1-34.1c0.6-0.4,1.3-1,2-1.4 c-1.3-9-2.9-18.9-5.8-27.6c-0.5,0.1-1.3,0.2-1.8,0.4c-5.2-9.3-8.2-20.8-13.1-30.3c0,0-3.2-4.2-3.2-4.2s-0.2-0.3-0.2-0.3 c0.5-0.4,1.5-0.9,1.8-1.5c2.9-0.4,6.9-2,9.8-2.8l-0.1-1.1l6-2l0.3,1.2c3.5-1.3,9.2-3.3,12.7-4.7l-0.4-1.3c0.9-0.3,4.1-1.5,5-1.8 l-0.1-0.4l1.2-0.4L220.2,49.4z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 259.7601 120.3194)"
        >
          Worringen
        </text>
      </g>
    </>
  );
});
